import React from 'react';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';
import { Image, Transformation, CloudinaryContext } from 'cloudinary-react';
import cloudinary from 'cloudinary-core';
import ContactModel from '../components/ContactModel';
import gooseLogo from './../assets/icon/goose-logo.svg';
import { graphql } from 'gatsby';
import Layout from '../components/Layout/layout';

const cloudinaryCore = new cloudinary.Cloudinary({ cloud_name: 'wearegoosebclandingpage', secure: true });

const LogoGoose = () => (
  <svg viewBox={gooseLogo.viewBox}>
    <use xlinkHref={gooseLogo.url} />
  </svg>
);

class MagnessCaseStudyRoute extends React.Component {
  state = { contactModelActive: false };

    handleContactModelClick = () => {
      this.setState({ contactModelActive: !this.state.contactModelActive });
    };


    render() {
      const {
        contactEmail,
        title,
        subtitle,
        contactNumber,
        keywords,
        socialLinks
      } = this.props.data.site.siteMetadata;
      return (
        <Layout>
        <section className="c-caseStudy l-homeServices">
          <CloudinaryContext cloudName="wearegoosebclandingpage" fetchFormat="auto" secure="true" width="auto" quality="auto" dpr="auto">
            <div className="vc-bg-lines dark"><div /><div /><div /><div /><div /><div /><div /></div>


            <div className="c-jobDescription">
              <div className="u-container u-container--s"><br /><br /><br />
                <div className="l-bigCommerceBenefits__badge">
                  <a href="/"><LogoGoose /></a>
                </div><br /><br /><br />
                <div className="c-caseStudyIntro l-caseStudyIntro">
                  <div className="l-caseStudyIntro__text">
                    <p className="o-tgQuote">Client: Magness Benrow / Platform: BigCommerce</p>
                    <h1>Making change today, for sustainable growth tomorrow</h1>
                    <p className="u-intro">How we rescued a NZ retailer from a dying platform, and propelled them into their next phase of growth.
                    </p> <br />

                    <p>Before we start, it’s important that you know something about us: we love brazen honesty and a good story. </p>
                    <p>(This one’s great)</p>
                    <p>We’ll share as much as we can. But if, by the end of this case study, you still have unanswered questions, please get in touch at <a href="mailto:info@wearegoose.co.nz">info@wearegoose.co.nz</a>. </p>
                  </div>
                  <div className="l-caseStudyIntro__image">
                    <Image alt="Magness BigCommerce Website" publicId="lp-case-mac" />
                  </div>
                </div>
                <div className="u-container u-container--xs">
                  <h2 className="u-h3">Tired of long, self-aggrandising case studies?</h2>

                  <p><strong>Here’s the short version:</strong></p>
                  <ul>
                    <li>Magento 1 was reaching end of life, and Magness Benrow needed to move platforms.</li>
                    <li>BigCommerce was an ideal fit, and their new online store launched in February 2020.</li>
                    <li>The world changed overnight. Hello pandemic. </li>
                    <li>We developed a flexible, proactive digital strategy in line with New Zealand’s 4 tier alert system.</li>
                    <li><strong>Magness Benrow experienced unprecedented growth in unprecedented times</strong> (keep reading for the figures).</li>
                    <li>We built an MYOB integration for BigCommerce and automated bulk price updates to help them keep up.</li>
                  </ul><br />
                  <br />
                </div>
                <div className="u-container u-container--m c-caseStudy__2x2">
                  <div className="col">
                    <Image className="u-imgFull" alt="Magness BigCommerce MYOB" publicId="75thannv-mb" />
                  </div>
                  <div className="col">
                    <Image className="u-imgFull" alt="Magness BigCommerce MYOB" publicId="75thannv-2-mb" />
                  </div>
                </div>
                <div className="u-container u-container--xs">
                  <h3>Our History</h3>
                  <p>Magness Benrow was one of our very first clients. They’re a retailer with a long history, and famous for their iconic radio ads.</p>
                  <p>They’re also a family business. While John, son of the original founder, calls the shots, much of the business is under the care of his children, who we work with directly.</p>
                  <p>With such a strong history of brick-and-mortar success, their online store and promotions has not always been the priority.</p>
                  <p>Our story starts when they were seeking support for their Magento store.</p>
                  <p>To understand what happened next, it’s important to first understand how we work with new clients. Our first approach is always to work with what we can.</p>
                  <p>Often, clients have been recommended “the latest technologies” and “cutting edge strategies”. These approaches often overlook the simple interventions that can have a bigger impact in a shorter time frame. As a result, they’re tired of conflicting advice, being told they just need to improve their page speed score, and just want things to be easier. We don’t blame them.</p>
                  <p>We focus on small, incremental change because we know that the implementation of high-return technologies and marketing strategies is not exclusive to the biggest companies (with the biggest budgets). It's about taking what you have and working it to your best advantage. </p>
                  <p>The move to Magento had already been a big step for Magness Benrow, so we spent the early years of our relationship maintaining and improving the environment where we could. </p>
                  <p>But, with a huge amount of manual processes and ongoing security updates and bug fixes, we’d continually draw their attention back to the big picture: one day, you’ll have to move.</p>
                  <p>But, the online store was generating revenue. They were making money. And it was doing its job.</p>
                  <p>Until Adobe announced that Magento 1 was being retired. The move was on the horizon.</p>
                </div> <br />
                <div className="u-container u-container--xs">
                  <h3>The move to <strong>BigCommerce</strong></h3>
                  <p>Magness Benrow had been understandably resistant to moving to a new platform. They had experienced what ‘starting from scratch’ looked like, and didn’t want to go there again.</p>
                  <p>But, thanks to our intimate knowledge of their system, we knew we could replicate what they already had - and improve it.</p>
                  <p>They wouldn’t be starting from scratch.</p>
                  <p>First, we took stock of their old online store. We reviewed the full set of features, how they were using the site, and customer feedback. We really considered what would help them grow in the way we always knew they could.</p>
                  <p>We compared a number of platforms and recommended BigCommerce for its flexibility and ease of management. It was 2019, and we didn’t know just how important those factors would be.</p>
                  <p>The build was carried out between December 2019 and February 2020 - and we saw an immediate growth in online sales.</p>
                  <p>Where their previous budget was mostly spent on development, with BigCommerce we could shift that spend to managing more agile and effective promotions. In the Magento environment, every promotion required 2 to 3 days minimum lead time, and access to a senior Magento developer to make the necessary changes.</p>
                  <p>Now, our clever marketers can quickly set up promotions, within a single business day.</p>
                  <p>When the site launched, it launched using a custom Mainfreight shipping integration embedded in the checkout process. By using a newly released shipping API (and being one of the first to do so) we were able to build an app to show live shipping rates from Mainfreight. This means that customers can receive accurate shipping costs, and both Magness Benrow and their customers save money.</p>
                  <hr className="c-caseStudy--hrspacer" />
                  <h4>Timeline</h4>
                  <ul>
                    <li><strong>December, 2019 →</strong> Build starts.</li>
                    <li><strong>February, 2020 →</strong> Site live….and New Zealand records it’s first COVID case.</li>
                    <li><strong>March, 2020 →</strong> 4 tier alert system announced, lock down starts.</li>
                    <li><strong>May, 2020 →</strong> Nationwide lock down lifts.</li>
                    <li><strong>March, 2021 →</strong> Magness Benrow records figures showing <strong>online growth of 358.91% EOFY</strong>.</li>
                    <li><strong>April, 2021 →</strong> Developed MYOB integration and introduced new automations.</li>
                    <li><strong>June, 2021 →</strong> Magness Benrow continues to show <strong>online growth of 18% compared to last year</strong>.</li>
                  </ul>
                  <hr className="c-caseStudy--hrspacer" /> <br />
                </div>

                <div className="u-container u-container--m">
                  <div className="col">
                    <Image className="u-imgFull" alt="Magness BigCommerce MYOB" publicId="covid-banner-mb" />
                  </div>
                </div>
                <br />
                <div className="u-container u-container--xs">
                  <br />
                  <h3>A COVID-safe strategy</h3>
                  <p>We tell our clients that they can shift their problems to us, so they can focus on their next big idea.</p>
                  <p>A global pandemic was no exception.</p>
                  <p>We proactively delivered a digital and marketing strategy to Magness Benrow to cover the scenarios we could plan for. Once the 4 tier alert system was announced, we made a plan to consider online operations and promotions under tiers 3 and 4, and adjustments depending on whether the stores would be considered essential or non-essential (a status which changed over the course of New Zealand’s national lockdown).</p>
                  <p>As the situation progressed, we were able to quickly respond and move to the most appropriate strategy for that moment in time.</p>
                  <p>These strategies focused on how we could take the entire in-store experience online. They prioritised:</p>
                  <ul>
                    <li>The use of a landing page to communicate the store’s status and COVID-safe practices with customers.</li>
                    <li>Increased use of email marketing.</li>
                    <li>Live chat.</li>
                    <li>Strategic homepage design and promotions, including promotion of click and collect.</li>
                  </ul>
                  <p>Of course, it is in our interests to be proactive. We agree to reach specific revenue targets as performance metrics with our clients, and we take on responsibility for the success of their online store. </p>
                  <p>And that's exactly what we did here.</p>
                  <p>For the first time, the online store has become a main pillar of the businesses.</p>
                  <p>Over 2020 - with a new site and responsive digital and marketing strategy - Magness Benrow’s conversion rate increased by 187.04%. <strong>Their revenue grew by 381% compared to the previous year</strong>.</p>
                  <br />
                  <h3>Growth, automation and integration</h3>
                  <p>Of course, not every cloud has a silver lining. This part of the story could also be called ‘expect the unexpected’.</p>
                  <p>Alongside the huge growth of online sales, one store’s visitation decreased sharply - including for click and collect. That store closed in 2020. But this isn’t a story of job loss. Instead, it’s about a job shift, as the team from that store are now involved in the fulfilment of online store orders. Online sales have replaced - and surpassed - that store’s sales.</p>
                  <p>Growth at this scale does expose issues with processes.</p>
                  <p>Magness Benrow were still printing and manually entering all online orders into the MYOB POS system, which was also being used to manage inventory. We developed an integration with MYOB’s POS software to automate this process. This ensures that the orders are entered accurately and provides the business real-time information on their inventory and stock levels. It also saves huge amounts of time and energy, freeing the team up to work on growth-focused projects.</p>
                  <p>We also took this opportunity to address one of the other manual processes in the business. Magness Benrow were manually having to update prices for their very regular price adjustments, and every promotion that was run. As they were changing the prices, products weren’t being added to categories to appear correctly in the online store.</p>
                  <p><strong>Now they:</strong></p>
                  <ol>
                    <li>Open a secure FTP portal online.</li>
                    <li>Upload a CSV file.</li>
                  </ol>
                  <p><strong>And a script developed by our team:</strong></p>
                  <ul>
                    <li>Updates the prices.</li>
                    <li>Sorts the products into categories based on their SKU.</li>
                  </ul> <br />
                  <p>This simple, low cost adjustment, has saved hours across the year, and freed up key staff at peak times. For example, they no longer have to spend their Christmas afternoon loading prices for Boxing Day sales. They can have a nap on the lounge like the rest of us! Taking this phased approach has helped them manage their budget, while still competing with larger competitors.</p>
                  <br />
                  <h3>Our roadmap</h3>
                  <p>The growth experienced during the pandemic has held. </p>
                  <p>This isn’t a one off.</p>
                  <p>Once the BigCommerce site was live, the growth experienced in 2020 shifted our focus to automations and integrations.</p>
                  <p>This will continue to be the focus going forward. We’ll need to keep looking at streamlining systems so the online store can run as efficiently as possible.</p>
                  <p>We always take a phased approach, to help our clients manage change, time, and cashflow, and take on the competition.</p>
                  <p>Now, their biggest competitors are asking, “How did they do that?”</p>
                  <p>And we say, they found the right wingman.</p><br />
                  <h3 className="c-homeWork__results__header l-homeWork__results__header  u-brand-red" data-reactid="310">                <a href="/">Learn more about Goose &#8594;</a></h3>
                  <br /><br />
                  {/* <hr className="c-caseStudy--hrspacer" /> <br />

                  <h2>Need a new wingman?</h2>
                  <p>We help retailers succeed online. We can suggest a roadmap to help you make a big impact today, and grow steadily into the future. </p>
                  <p>It all starts with a conversation coffee (our shout!).</p> */}
                </div>
                <div className="c-apply">
                  <div className="c-applyBox l-applyBox">
                    <div className="c-applyBox__content">
                      <h4 className="c-applyBox__header u-h3">Need a new wingman?</h4>
                      <p>We help retailers succeed online. We can suggest a roadmap to help you make a big impact today, and grow steadily into the future.</p>
                      <p className="c-applyBox__text">It all starts with a <s>conversation</s> coffee (our shout!). <a href="mailto:info@wearegoose.co.nz">Get in touch.</a> </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ContactModel toggleContactModel={this.handleContactModelClick} siteMeta={this.props.data.site.siteMetadata} ContactModelStatus={this.state.contactModelActive} />

          </CloudinaryContext>
        </section>
        </Layout>
      );
    }
}

export default MagnessCaseStudyRoute;

export const pageQuery = graphql`
  query MagnessCaseStudyRouteQuery {
    site {
      siteMetadata {
        title
        subtitle
        copyright
        contactEmail
        menu {
          label
          path
        }
        author {
          name
          email
          telegram
          twitter
          github
          rss
          vk
        }
      }
    }
  }
`;
